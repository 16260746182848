export default {
  isLoadingPermissions: state => state.loadingPermissions,
  permissions         : state => state.permissions
    .map(permission => {
      if (permission.selectable && permission.dependencies?.dependency.length === 1) {
        const dependentPermission = state.permissions.find(item => item.id === permission.dependencies.dependency[0])
        if (!dependentPermission.selectable) {
          return {
            ...permission,
            dependencies: JSON.parse(JSON.stringify(dependentPermission.dependencies))
          }
        }
      }
      return permission
    })
    .sort((permission1, permission2) => permission1.sortingOrder - permission2.sortingOrder)
}