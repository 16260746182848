import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to channels page.
 */
export const beforeChannels = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CHANNELS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadDomains  = store.dispatch("domains/loadDomains")
  const loadChannels = store.dispatch("channels/loadChannels")
  Promise.all([loadDomains, loadChannels]).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}