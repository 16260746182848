import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { PAGES } from "@/constants"

/**
* This method will be called before navigating to Configurations page.
*/
export const beforeConfigurations = async (to, from) => {
  if ( from.name !== "configurations" || !(PAGES.CONFIGURATIONS.includes(to.hash) || PAGES.CONFIGURATIONS.includes(from.hash))) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    const promisesArrayToBeResolved = []
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CONFIGURATIONS))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("configurations/resetUpdatingConfiguration")
    store.commit("configurations/resetConfigurationUpdated")
    store.commit("configurations/resetConfigurationUpdateError")
    const loadConfigurations         = store.dispatch("configurations/loadConfigurations")
    const loadTranslationPreferences = store.dispatch("translationPreferences/loadTranslationPreferences")
    const loadGroups                 = store.dispatch("groups/loadGroups")
    const loadUsers                  = store.dispatch("users/loadUsers")
    promisesArrayToBeResolved.push(
      loadConfigurations,
      loadTranslationPreferences,
      loadGroups,
      loadUsers
    )

    if (store.getters["configurations/isDefaultSsoConfigured"] || store.getters["configurations/isDefaultSsoEnabled"] ) {
      const loadDefaultSsoConfigurationPromise = store.dispatch("configurations/loadDefaultSsoConfiguration")
      promisesArrayToBeResolved.push(loadDefaultSsoConfigurationPromise)
    }

    const loadRolesPromise = store.dispatch("roles/loadRoles")
    promisesArrayToBeResolved.push(loadRolesPromise)
    const loadDataRetentionRules   = store.dispatch("dataRetentionRules/loadDataRetentionRules")
    const loadDataRetentionPeriods = store.dispatch("dataRetentionPeriods/loadDataRetentionPeriods")
    promisesArrayToBeResolved.push(
      loadDataRetentionRules,
      loadDataRetentionPeriods
    )
    const loadFieldsPromise = store.dispatch("fields/loadFieldsV2")
    promisesArrayToBeResolved.push(loadFieldsPromise)
    Promise.all(promisesArrayToBeResolved)
      .then(() => {
        const globalRoleIds = []
        for (const role of store.getters["roles/roles"]) {
          if (role.global) {
            globalRoleIds.push(role.id)
          }
        }
        let  loadAccessesPromise
        if (globalRoleIds.length) {
          loadAccessesPromise = store.dispatch("accesses/loadAccesses", {
            roleId: globalRoleIds.toString()
          })
        }
        return Promise.all([loadAccessesPromise])
      })
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  }
}