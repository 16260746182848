/**
 * @file It contains vuex token module mutations to mutate the state variables.
 */
export default {
  setPageFreezed: (state, pageFreeze) => {
    state.pageFreeze = pageFreeze
  },
  setNotify: (state, notify) => {
    state.notify = notify
  },
  setConfigurations: (state, configurations) => {
    state.configurations = configurations
  },
  setClientName: (state, clientName) => {
    state.clientName = clientName
  },
  setBreadcrumbs: (state, breadcrumbs) => {
    state.breadcrumbs = breadcrumbs
  },
  setNotification: (state, notification) => {
    state.notification = notification
  },
  setUnexpectedError: (state, unexpectedError) => {
    state.unexpectedError = unexpectedError
  },
  setPageTitle: (state, pageTitle) => {
    state.pageTitle = pageTitle
  },
  setNotFoundError: (state, notFoundError) => {
    state.notFoundError = notFoundError
  },
  setForbiddenError: (state, forbiddenError) => {
    state.forbiddenError = forbiddenError
  },
  setPermanentNotification: (state, permanentNotification) => {
    state.permanentNotification = permanentNotification
  },
  setNotificationTimeout: (state, notificationTimeout) => {
    state.notificationTimeout = notificationTimeout
  },
  setProgressBarInitiated: (state, isProgressBarInitiated) => {
    state.isProgressBarInitiated = isProgressBarInitiated
  },
  setProgressBarPromisesPending: (state, isProgressBarPromisesPending) => {
    state.isProgressBarPromisesPending = isProgressBarPromisesPending
  },
  setStartInterval: (state, interval) => {
    state.startInterval = interval
  },
  setCountdownInterval(state, interval) {
    state.countdownInterval = interval
  },
  setRemainingTime(state, time) {
    state.remainingTime = time
  },
  setTokenIsAboutToExpire(state, value) {
    state.tokenIsAboutToExpire = value
  }
}