import { defaultTableHeaderClasses } from "@/constants"
export const ROLE_PERMISSIONS = {
  headers: [{
    align: "left",
    value: "data-table-select",
    width: "40",
    class: defaultTableHeaderClasses
  }, {
    text    : "1895",
    align   : "left",
    value   : "name",
    class   : defaultTableHeaderClasses,
    sortable: false
  }]
}