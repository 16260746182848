import { defaultTableHeaderClasses } from "@/constants"

export const CSV_DATA_VALIDATION = {
  headers: [{
    text    : "1911",
    value   : "header",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }, {
    text     : "1912",
    value    : "example",
    align    : "left",
    class    : defaultTableHeaderClasses,
    cellClass: "text-truncate max-width-350",
    width    : 350,
    sortable : false
  }, {
    text    : "1913",
    value   : "speakupFields",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false,
    width   : 350
  }]
}