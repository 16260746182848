import store from "@/plugins/vuex"
import { ISSUE_ROLES } from "@/constants"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DOMAIN } from "@/constants/bread-crumbs/domain"
import { PAGES } from "@/constants"

/**
 * This method will be called before navigating to domain page.
 * @param {*} to route to be navigated to.
 */
export const beforeDomain = async (to, from) => {
  if (from.name !== "domain" || !(PAGES.DOMAIN.includes(to.hash) || PAGES.DOMAIN.includes(from.hash))) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    const promisesArrayToBeResolved = []
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.DOMAIN))
    await store.dispatch("domains/loadDomain", +to.params.id)
    const domains = store.getters["domains/domains"]
    const domain  = domains.find(domain => domain.id === +to.params.id)
    if (!domain) {
      return "not-found"
    } else {
      store.commit("shared/setBreadcrumbs", DOMAIN(to, domain))
      const loadChannels = store.dispatch("channels/loadChannels")
      promisesArrayToBeResolved.push(loadChannels)
      await store.dispatch("groups/loadGroups")
      const  loadUsers = store.dispatch("users/loadUsers")
      promisesArrayToBeResolved.push(loadUsers)
      const groups = store.getters["groups/groups"]
      for (const group of groups) {
        const loadGroupUsers = store.dispatch("groups/loadUsers", group.id)
        promisesArrayToBeResolved.push(loadGroupUsers)
      }
      const loadRoles    = store.dispatch("roles/loadRoles")
      const loadAccesses = store.dispatch("accesses/loadAccesses", { roleId: ISSUE_ROLES })
      store.commit("domains/resetUpdatingDomain")
      store.commit("domains/resetDomainUpdated")
      store.commit("domains/resetDomainUpdateError")
      store.commit("accesses/setAddingAccess", undefined)
      store.commit("accesses/setAccessAdded", undefined)
      store.commit("accesses/setRemovingAccess", undefined)
      store.commit("accesses/setAccessRemoved", undefined)
      const loadDomains       = store.dispatch("domains/loadDomains")
      const loadFieldsV2      = store.dispatch("fields/loadFieldsV2")
      const loadFormTemplates = store.dispatch("formTemplates/loadFormTemplates")
      promisesArrayToBeResolved.push(
        loadRoles,
        loadAccesses,
        loadDomains,
        loadFieldsV2,
        loadFormTemplates
      )

      Promise.all(promisesArrayToBeResolved).then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
    }
  }
}