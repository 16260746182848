import {
  HTTP_STATUS_CODE,
  AI_ASSISTANCE_STATUS
} from "@/constants"
import { GET_HTTP_CLIENT } from "@/api"
import { getBaseURL } from "@/utils"

const path = "/v1/ai-assistances"

export default {
  /**
   * This action loads the aiAssistanceValue.
   * @param {*} context is the store.
   * @param {*} payload contains id of the aiAssistance.
   */
  async loadAiAssistanceValue(context, payload) {
    if (payload?.status === AI_ASSISTANCE_STATUS.INITIATED) {
      delete payload.status
    }
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const id = payload.id

    const getAiAssistanceResponse = await httpClient.get(`/${id}`)
    if (getAiAssistanceResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiAssistanceValue", getAiAssistanceResponse.data)
    }
  },

  /**
   * This action will add new aiAssistance for an issue.
   * @param {*} context is the store.
   * @param {*} payload is the name to add to the aiAssistance.
   */
  async addAiAssistance(context, payload) {
    context.commit("setAddingAiAssistance", true)
    context.commit("setAiAssistanceAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postAiAssistanceResponse = await httpClient.post(undefined, payload)
    if (postAiAssistanceResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiAssistanceValue", postAiAssistanceResponse.data)
      context.commit("setAiAssistanceAdded", true)
    }
    context.commit("setAddingAiAssistance", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setAiAssistanceValue", new Object())
    context.commit("setLoadingAiAssistance", undefined)
    context.commit("setAddingAiAssistance", undefined)
    context.commit("setAiAssistanceAdded", undefined)
  }
}