import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeWorkflows = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.WORKFLOWS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadIssueTypes           = store.dispatch("issueTypes/loadIssueTypes")
  const loadWorkflows            = store.dispatch("workflows/loadWorkflows")
  const loadWorkflowAssociations = store.dispatch("workflowAssociations/loadWorkflowAssociations")
  Promise.all([loadIssueTypes, loadWorkflows, loadWorkflowAssociations])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}