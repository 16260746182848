/**
 * @file It contains vuex issues module mutations to mutate the state variables.
 */
export default {
  setLoadingIssues: (state, loadingIssues) => {
    state.loadingIssues = loadingIssues
  },
  setLoadingIssue: (state, loadingIssue) => {
    state.loadingIssue = loadingIssue
  },
  setIssues: (state, issues) => {
    state.issues = issues
  },
  updateIssues: (state, issues) => {
    for (const issue of issues) {
      const index = state.issues.findIndex(stateIssue => stateIssue?.id === issue.id)
      if (index >= 0) {
        state.issues.splice(index, 1, { ...state.issues[index], ...issue })
      } else {
        state.issues.push(issue)
      }
    }
  },
  setUpdatingIssue: (state, properties) => {
    for (const property of properties) {
      state.updatingIssue[property] = true
    }
  },
  resetUpdatingIssue: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingIssue[property] = false
      }
    } else {
      Object.keys(state.updatingIssue).forEach(property => state.updatingIssue[property] = false)
    }
  },
  setIssueUpdated: (state, properties) => {
    for (const property of properties) {
      state.issueUpdated[property] = true
      setTimeout(() => {
        state.issueUpdated[property] = false
      }, 5000)
    }
  },
  resetIssueUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.issueUpdated[property] = false
      }
    } else {
      Object.keys(state.issueUpdated).forEach(property => state.issueUpdated[property] = false)
    }
  },
  setLoadingLogs: (state, loadingLogs) => {
    state.loadingLogs = loadingLogs
  },
  setLogsOfIssue: (state, { id, logs }) => {
    state.logsOfIssues = Object.assign({}, state.logsOfIssues, { [id]: logs })
  },
  resetLogsOfIssues: state => {
    state.logsOfIssues = new Object()
  },
  setAccessInformation: (state, { policy, policyAccessInformation }) => {
    const policyAccessInformationResourceMap = new Object()
    for (const resource of policyAccessInformation) {
      policyAccessInformationResourceMap[resource.id] = resource
    }

    if (state.accessInformation[policy]) {
      state.accessInformation[policy] = Object.assign(
        {},
        state.accessInformation[policy],
        policyAccessInformationResourceMap
      )
    } else {
      state.accessInformation = Object.assign(
        {},
        state.accessInformation, {
          [policy]: policyAccessInformationResourceMap
        }
      )
    }
  },
  resetAccessInformation: state => {
    state.accessInformation = new Object()
  },
  setIssueAdded: (state, issueAdded) => {
    state.issueAdded = issueAdded
  },
  setAddingIssue: (state, addingIssue) => {
    state.addingIssue = addingIssue
  },
  setLastSearchIssues: (state, lastSearchIssues) => {
    state.lastSearchIssues = lastSearchIssues
  },
  setUploadingCsvForBulkImportCases: (state, uploadingCsvForBulkImportCases) => {
    state.uploadingCsvForBulkImportCases = uploadingCsvForBulkImportCases
  },
  setCsvFileUploadedForBulkImportCases: (state, csvFileUploadedForBulkImportCases) => {
    state.csvFileUploadedForBulkImportCases = csvFileUploadedForBulkImportCases
  }
}