import store from "@/plugins/vuex"
import { BOOLEAN } from "@/constants"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to issue forms page.
 */
export const beforeIssueForms = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_FORMS))
  store.commit("shared/setBreadcrumbs", undefined)
  const  loadFormTemplates = store.dispatch("formTemplates/loadFormTemplates", {
    reportForm: BOOLEAN.FALSE
  })
  Promise.all([loadFormTemplates])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}