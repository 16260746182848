import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeAutomations = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.AUTOMATIONS))
  store.commit("shared/setBreadcrumbs", undefined)
  const  loadAutomationsPromise = store.dispatch("automations/loadAutomations")
  Promise.all([loadAutomationsPromise]).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}