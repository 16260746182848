import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to roles page.
 */
export const beforeRoles = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ROLES))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadPermissions = store.dispatch("permissions/loadPermissions")
  const loadRoles       = store.dispatch("roles/loadRoles")
  Promise.all([loadPermissions, loadRoles])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}
