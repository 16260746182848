export default {
  setFilters: (state, filters) => {
    state.filters = filters
  },
  setAddingFilter: (state, addingFilter) => {
    state.addingFilter = addingFilter
  },
  setFilterAdded: (state, filterAdded) => {
    state.filterAdded = filterAdded
  },
  updateFilters: (state, filters) => {
    for (const filter of filters) {
      const index = state.filters.findIndex(stateFilter => stateFilter?.id === filter.id)
      if (index >= 0) {
        state.filters.splice(index, 1, { ...state.filters[index], ...filter })
      } else {
        state.filters.push(filter)
      }
    }
  },
  setFilterAddError: (state, filterAddError) => {
    state.filterAddError = filterAddError
  },
  setLoadingFilters: (state, loadingFilters) => {
    state.loadingFilters = loadingFilters
  },
  setRemovingFilter: (state, removingFilter) => {
    state.removingFilter = removingFilter
  },
  setFilterRemoved: (state, filterRemoved) => {
    state.filterRemoved = filterRemoved
  },
  removeFilter: (state, filter) => {
    const index = state.filters.findIndex(eachFilter =>
      eachFilter.id === filter.id)
    if (index >= 0) {
      state.filters.splice(index, 1)
    }
  },
  setUpdatingFilter: (state, properties) => {
    for (const property of properties) {
      state.updatingFilter[property] = true
    }
  },
  resetFilterUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.filterUpdated[property] = false
      }
    } else {
      Object.keys(state.filterUpdated).forEach(property => state.filterUpdated[property] = false)
    }
  },
  setFilterUpdated: (state, properties) => {
    for (const property of properties) {
      state.filterUpdated[property] = true
      setTimeout(() => {
        state.filterUpdated[property] = false
      }, 5000)
    }
  },
  resetUpdatingFilter: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingFilter[property] = false
      }
    } else {
      Object.keys(state.updatingFilter).forEach(property => state.updatingFilter[property] = false)
    }
  },
  setFilterUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.filterUpdateError[property] = error
    }
  },
  resetFilterUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.filterUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.filterUpdateError).forEach(property => state.filterUpdateError[property] = undefined)
    }
  },
  setLoadingFilter: (state, loadingFilter) => {
    state.loadingFilter = loadingFilter
  }
}