import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/saved-filters"

export default {

  /**
   * This action adds a new filter.
   * @param {*} context is the store.
   */
  async addFilter(context, payload) {
    context.commit("setAddingFilter", true)
    context.commit("setFilterAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFilterAddResponse = await httpClient.post(undefined, payload)

    if (getFilterAddResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFilters", [getFilterAddResponse.data])
      context.commit("setFilterAdded", true)
    } else if (getFilterAddResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFilterAddError", getFilterAddResponse.data)
    }

    context.commit("setAddingFilter", false)
  },

  /**
    * This action will load existing filters.
    * @param {*} context is the store.
    * @param {*} payload is the filter criteria.
    */
  async loadFilters(context, payload) {
    context.commit("setLoadingFilters", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFiltersResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFiltersResult.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateFilters", getFiltersResult.data)
      } else {
        context.commit("setFilters", getFiltersResult.data)
      }
    }

    context.commit("setLoadingFilters", false)
  },

  /**
   * This action removes a saved filter.
   * @param {*} context is the store.
   * @param {*} payload contains saved filter id.
   */
  async removeFilter(context, payload) {
    context.commit("setRemovingFilter", true)
    context.commit("setFilterRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const removeFilterResponse = await httpClient.delete(`/${payload.id}`)

    if (removeFilterResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeFilter", payload)
      context.commit("setFilterRemoved", true)
    }
    context.commit("setRemovingFilter", false)
  },

  /**
   * This action updates a particular filter.
   * @param {*} context is the store.
   * @param {*} payload it is id and data of the filter to be updated
   */
  async updateFilter(context, payload) {
    const id = payload.id
    delete payload.id
    const propertiesToBeUpdated = Object.keys(payload)
    for (const property of propertiesToBeUpdated) {
      if (payload[property] === undefined) {
        payload[property] = null
      }
    }
    context.commit("setUpdatingFilter", propertiesToBeUpdated)
    context.commit("resetFilterUpdated", propertiesToBeUpdated)
    context.commit("resetFilterUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFilterResponse = await httpClient.put(`/${id}`, payload)

    if (updateFilterResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      if (payload.groups) {
        const groups   = context.rootGetters["groups/groups"]
        payload.groups = payload.groups.map(groupId =>
          groups.find(group => group.id === groupId)
        )
      }
      context.commit("updateFilters", [{
        id,
        ...payload
      }])
      context.commit("setFilterUpdated", propertiesToBeUpdated)
    } else if (updateFilterResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFilterUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateFilterResponse.data
      })
    }

    context.commit("resetUpdatingFilter", propertiesToBeUpdated)
  },

  /**
   * This action load a particular filter.
   * @param {*} context is the store.
   * @param {*} payload it is id of the filter to be loaded
   */
  async loadFilter(context, payload) {
    context.commit("setLoadingFilter", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFilterResponse = await httpClient.get(`/${payload.id}`)

    if (getFilterResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFilters", [getFilterResponse.data])
    }
    context.commit("setLoadingFilter", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setFilters", [])
    context.commit("setLoadingFilters", false)
    context.commit("setAddingFilter", false)
    context.commit("setFilterAdded", false)
    context.commit("setFilterAddError", null)
    context.commit("setRemovingFilter", false)
    context.commit("setFilterRemoved", false)
    context.commit("resetUpdatingFilter")
    context.commit("resetFilterUpdated")
    context.commit("resetFilterUpdateError")
    context.commit("setLoadingFilter", false)
    context.commit("setLoadingFilters", false)
  }
}