import store from "@/plugins/vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { WORKFLOW } from "@/constants/bread-crumbs/workflow"
import { getFromLocalStorage } from "@/utils"

export const beforeWorkflow = async (to, from) => {
  const workflows = getFromLocalStorage("workflows")
  const workflow  = workflows.find(workflow => workflow.id === +to.params.id)
  if (!workflow) {
    return {
      name: "not-found"
    }
  }
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(workflow.name))
  store.commit("shared/setBreadcrumbs", WORKFLOW({ params: { id: +to.params.id } }, workflow.name))
  const loadIssueTypes = store.dispatch("issueTypes/loadIssueTypes")
  if(from.name !== "workflows"){
    await store.dispatch("workflowAssociations/loadWorkflowAssociations")
  }

  if (to.name === "workflow-status-side-panel") {
    const workflowStatus = workflow.statuses.find(status => status.name === to.query.status)
    if (!workflowStatus) {
      return {
        name  : "workflow",
        params: {
          id: +to.params.id
        }
      }
    }
  }
  Promise.all([loadIssueTypes])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}