import store from "@/plugins/vuex"
import { REPORTER_INTAKE_FORM } from "@/constants/bread-crumbs/reporter-intake-form"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to reporter intake form page.
 */
export const beforeReporterIntakeForm = async (to, from) => {

  let loadConfigurationsPromise
  if (!["reporter-intake-form", "reporter-intake-form-template-configuration"].includes(from.name)) {

    await store.dispatch("formTemplates/loadFormTemplate", +to.params.id)
    const formTemplates = store.getters["formTemplates/formTemplates"]
    const formTemplate  = formTemplates.find(optionList => optionList.id === +to.params.id)

    if (!formTemplate) {
      return {
        name: "not-found"
      }

    } else if (formTemplate && !formTemplate.reportForm) {
      return "reporter-intake-forms"
    }
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(formTemplate.name))
    store.commit("shared/setBreadcrumbs", REPORTER_INTAKE_FORM(to, formTemplate.name))

    const loadFieldsV2        = store.dispatch("fields/loadFieldsV2")
    const loadOptionLists     = store.dispatch("optionLists/loadOptionLists")
    const loadOptionListItems = store.dispatch("optionListItems/loadOptionListItems")
    loadConfigurationsPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", { formTemplateId: +to.params.id })
    const loadChannels        = store.dispatch("channels/loadChannels")

    if (to.name === "reporter-intake-form-template-configuration") {
      await loadConfigurationsPromise
    }
    Promise.all([loadFieldsV2, loadOptionLists, loadOptionListItems, loadChannels])
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  }

  if (to.name === "reporter-intake-form-template-configuration") {
    const formTemplateConfigurations = store.getters["formTemplateConfigurations/formTemplateConfigurations"]
    const formTemplateConfiguration  = formTemplateConfigurations.find(configuration =>
      configuration.formTemplateId === +to.params.id &&
      configuration.id === +to.params.configurationId
    )

    if (!formTemplateConfiguration) {
      return {
        name  : "reporter-intake-form",
        params: { id: +to.params.id }
      }
    }
  }
}