import { mergeArrayOfObjects } from "@/utils"

export default {
  setLoggedInUserPolicies: (state, loggedInUserPolicies) => {
    state.loggedInUserPolicies = loggedInUserPolicies
  },
  setIssueViewPolicies: (state, issueViewPolicies) => {
    mergeArrayOfObjects(state.issueViewPolicies, issueViewPolicies)
  },
  setIssueUpdatePolicies: (state, issueUpdatePolicies) => {
    mergeArrayOfObjects(state.issueUpdatePolicies, issueUpdatePolicies)
  },
  setIssueCommentAddPolicies: (state, issueCommentAddPolicies) => {
    mergeArrayOfObjects(state.issueCommentAddPolicies, issueCommentAddPolicies)
  },
  setIssueCommentRemovePolicies: (state, issueCommentRemovePolicies) => {
    mergeArrayOfObjects(state.issueCommentRemovePolicies, issueCommentRemovePolicies)
  },
  setIssueAddDocumentsPolicies: (state, issueAddDocumentsPolicies) => {
    mergeArrayOfObjects(state.issueAddDocumentsPolicies, issueAddDocumentsPolicies)
  },
  setIssueDocumentRemovePolicies: (state, issueDocumentRemovePolicies) => {
    mergeArrayOfObjects(state.issueDocumentRemovePolicies, issueDocumentRemovePolicies)
  },
  setReportViewPolicies: (state, reportViewPolicies) => {
    mergeArrayOfObjects(state.reportViewPolicies, reportViewPolicies)
  },
  setReportUpdatePolicies: (state, reportUpdatePolicies) => {
    mergeArrayOfObjects(state.reportUpdatePolicies, reportUpdatePolicies)
  },
  setReportContentClearPolicies: (state, reportContentClearPolicies) => {
    mergeArrayOfObjects(state.reportContentClearPolicies, reportContentClearPolicies)
  },
  setReportAddMessagesPolicies: (state, reportAddMessagesPolicies) => {
    mergeArrayOfObjects(state.reportAddMessagesPolicies, reportAddMessagesPolicies)
  },
  setMessageUpdatePolicies: (state, messageUpdatePolicies) => {
    mergeArrayOfObjects(state.messageUpdatePolicies, messageUpdatePolicies)
  },
  setMessageRemovePolicies: (state, messageRemovePolicies) => {
    mergeArrayOfObjects(state.messageRemovePolicies, messageRemovePolicies)
  },
  setMessageItemAddTranslationsPolicies: (state, messageItemAddTranslationsPolicies) => {
    mergeArrayOfObjects(state.messageItemAddTranslationsPolicies, messageItemAddTranslationsPolicies)
  },
  setTranslationUpdatePolicies: (state, translationUpdatePolicies) => {
    mergeArrayOfObjects(state.translationUpdatePolicies, translationUpdatePolicies)
  },
  setIssueAddFormInstancesPolicies: (state, issueAddFormInstancesPolicies) => {
    mergeArrayOfObjects(state.issueAddFormInstancesPolicies, issueAddFormInstancesPolicies)
  },
  setFormInstanceUpdatePolicies: (state, formInstanceUpdatePolicies) => {
    mergeArrayOfObjects(state.formInstanceUpdatePolicies, formInstanceUpdatePolicies)
  },
  setFormInstanceRemovePolicies: (state, formInstanceRemovePolicies) => {
    mergeArrayOfObjects(state.formInstanceRemovePolicies, formInstanceRemovePolicies)
  },
  setIssueUpdateIssueFieldValuesPolicies: (state, issueUpdateIssueFieldValuesPolices) => {
    mergeArrayOfObjects(state.issueUpdateIssueFieldValuesPolices, issueUpdateIssueFieldValuesPolices)
  },
  setIssuePrintPolicies: (state, issuePrintPolicies) => {
    mergeArrayOfObjects(state.issuePrintPolicies, issuePrintPolicies)
  },
  setIssueLinkAddPolicies: (state, issueLinkAddPolicies) => {
    mergeArrayOfObjects(state.issueLinkAddPolicies, issueLinkAddPolicies)
  },
  setIssueAddTaskPolicies: (state, issueAddTaskPolicies) => {
    mergeArrayOfObjects(state.issueAddTaskPolicies, issueAddTaskPolicies)
  },
  setIssueLinkRemovePolicies: (state, issueLinkRemovePolicies) => {
    mergeArrayOfObjects(state.issueLinkRemovePolicies, issueLinkRemovePolicies)
  },
  setIssueAddFoldersPolicies: (state, issueAddFoldersPolicies) => {
    mergeArrayOfObjects(state.issueAddFoldersPolicies, issueAddFoldersPolicies)
  },
  setIssueDocumentUpdatePolicies: (state, issueDocumentUpdatePolicies) => {
    mergeArrayOfObjects(state.issueDocumentUpdatePolicies, issueDocumentUpdatePolicies)
  },
  setMessageItemUpdatePolicies: (state, messageItemUpdatePolicies) => {
    mergeArrayOfObjects(state.messageItemUpdatePolicies, messageItemUpdatePolicies)
  },
  setFolderUpdatePolicies: (state, folderUpdatePolicies) => {
    mergeArrayOfObjects(state.folderUpdatePolicies, folderUpdatePolicies)
  },
  setFolderRemovePolicies: (state, folderRemovePolicies) => {
    mergeArrayOfObjects(state.folderRemovePolicies, folderRemovePolicies)
  },
  setIssueViewLogsPolicies: (state, issueViewLogsPolicies) => {
    mergeArrayOfObjects(state.issueViewLogsPolicies, issueViewLogsPolicies)
  },
  setAccessViewPolicies: (state, accessViewPolicies) => {
    mergeArrayOfObjects(state.accessViewPolicies, accessViewPolicies)
  },
  setSavedFilterRemovePolicies: (state, savedFilterRemovePolicies) => {
    mergeArrayOfObjects(state.savedFilterRemovePolicies, savedFilterRemovePolicies)
  },
  setSavedFilterUpdatePolicies: (state, savedFilterUpdatePolicies) => {
    mergeArrayOfObjects(state.savedFilterUpdatePolicies, savedFilterUpdatePolicies)
  },
  resetAllPolicies: state => {
    state.issueCommentRemovePolicies         = new Array(),
    state.issueCommentAddPolicies            = new Array(),
    state.issueUpdatePolicies                = new Array(),
    state.issueViewPolicies                  = new Array(),
    state.issueAddDocumentsPolicies          = new Array(),
    state.issueDocumentRemovePolicies        = new Array(),
    state.reportViewPolicies                 = new Array(),
    state.reportUpdatePolicies               = new Array(),
    state.reportAddMessagesPolicies          = new Array(),
    state.messageUpdatePolicies              = new Array(),
    state.messageRemovePolicies              = new Array(),
    state.messageItemAddTranslationsPolicies = new Array(),
    state.translationUpdatePolicies          = new Array(),
    state.issueAddFormInstancesPolicies      = new Array(),
    state.formInstanceUpdatePolicies         = new Array(),
    state.formInstanceRemovePolicies         = new Array(),
    state.issueUpdateIssueFieldValuesPolices = new Array(),
    state.issuePrintPolicies                 = new Array(),
    state.issueLinkAddPolicies               = new Array(),
    state.issueLinkRemovePolicies            = new Array(),
    state.issueAddTaskPolicies               = new Array(),
    state.reportContentClearPolicies         = new Array(),
    state.issueAddFoldersPolicies            = new Array(),
    state.issueDocumentUpdatePolicies        = new Array(),
    state.messageItemUpdatePolicies          = new Array(),
    state.folderUpdatePolicies               = new Array(),
    state.folderRemovePolicies               = new Array(),
    state.savedFilterUpdatePolicies          = new Array(),
    state.savedFilterRemovePolicies          = new Array()
  }
}