export const FILTER = (route, filter) => ([
  {
    text          : "2064",
    exact         : true,
    to            : "/filters",
    useTranslation: true
  },
  {
    text          : filter.name,
    to            : `/filters/${route.params.filterId}`,
    useTranslation: false
  }
])