export default {
  loadingFilters: false,
  filters       : new Array(),
  addingFilter  : false,
  filterAdded   : false,
  filterAddError: null,
  removingFilter: false,
  filterRemoved : false,
  updatingFilter: {
    name            : false,
    criteria        : false,
    columnPreference: false,
    access          : false
  },
  filterUpdated: {
    name            : false,
    criteria        : false,
    columnPreference: false,
    access          : false
  },
  filterUpdateError: {
    name: undefined
  },
  loadingFilter: false
}