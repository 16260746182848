import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/form-instance-field-values"

export default {
  async saveFormInstanceFieldValues(context, payload) {
    context.commit("setAddingFormInstanceFieldValues", true)
    context.commit("setFormInstanceFieldValuesAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const saveFormInstanceFieldValuesResult = await httpClient.post(`/${payload.id}/draft`, { value: payload.value })

    if (saveFormInstanceFieldValuesResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFormInstanceFieldValues", [saveFormInstanceFieldValuesResult.data])
      context.commit("setFormInstanceFieldValuesAdded", true)
    }

    context.commit("setAddingFormInstanceFieldValues", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setAddingFormInstanceFieldValues", false)
    context.commit("setFormInstanceFieldValuesAdded", false)
  }
}