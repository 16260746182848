import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to groups page..
 * @param {*} to route to be navigated to.
 * @param {*} from route from where navigation was triggered.
 */
export const beforeGroups = async (to, from) => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  const promisesArrayToBeResolved = []
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.GROUPS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.commit("groups/resetGroupsUsers")
  if (to.query.dialog === "GroupAdd") {
    store.commit("groups/setGroupAdded", undefined)
    store.commit("groups/setAddingGroup", undefined)
    store.commit("groups/setGroupAddError", undefined)
  }
  if (to.query.dialog !== "GroupAdd" && from.query.dialog !== "GroupAdd") {
    const loadGroupPromise = await store.dispatch("groups/loadGroups")
    promisesArrayToBeResolved.push(loadGroupPromise)
    const groups = store.getters["groups/groups"]
    for (const group of groups) {
      const loadUserPromiseToBeResolved = store.dispatch("groups/loadUsers", group.id)
      promisesArrayToBeResolved.push(loadUserPromiseToBeResolved)
    }
  }
  Promise.all(promisesArrayToBeResolved).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}