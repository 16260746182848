import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const ROLES = {
  headers: [{
    text : "78",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "80",
    align: "left",
    value: "description",
    class: defaultTableHeaderClasses
  },
  {
    text : "81",
    align: "left",
    value: "applicable",
    class: defaultTableHeaderClasses
  },
  {
    align: "left",
    value: "actions",
    class: defaultTableHeaderClasses
  }],
  loadingText : "84",
  noDataText  : "85",
  noResultText: "86",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "87"
  }
}