import store from "@/plugins/vuex"

/**
 * This method will be called to extract clientName.
 */
const extractClientName = () => {
  if (window.location.host.includes(9030)) {
    return process.env.VUE_APP_CLIENT_NAME
  } else {
    return window.location.host.split(".")[0]
  }
}
/**
 * This method will be called before navigating to login page.
 * @param {*} to route to be navigated to.
 * @param {*} from route from where navigation was triggered.
 */
export const beforeFirstLoad = async (to, from) => {
  if (!from.name && to.name !== "not-found") {
    store.commit("configurations/setSplashScreen", true)
    let clientName                        = store.getters["auth/clientName"]
    let clientUserPoolId                  = store.getters["auth/clientUserPoolId"]
    let clientUserPoolClientId            = store.getters["auth/clientUserPoolClientId"]
    const setUserPoolConfigurationsToAuth = async () => {
      await store.dispatch("auth/loadRegion")
      await store.dispatch("configurations/loadConfigurations")
      const configurations = store.getters["configurations/configurations"]

      clientUserPoolId = undefined
      store.commit("auth/setClientUserPoolId", clientUserPoolId)
      clientUserPoolClientId = undefined
      store.commit("auth/setClientUserPoolClientId", clientUserPoolClientId)

      if (configurations.length) {
        for(const configuration of configurations) {
          if (configuration.key === "CLIENT_USER_POOL_ID") {
            clientUserPoolId = configuration.value
            store.commit("auth/setClientUserPoolId", clientUserPoolId)
          }
          if (configuration.key === "CLIENT_USER_POOL_CLIENT_ID") {
            clientUserPoolClientId = configuration.value
            store.commit("auth/setClientUserPoolClientId", clientUserPoolClientId)
          }
        }
      }
    }
    if (!clientName) {
      clientName = extractClientName()
      if (clientName) {
        store.commit("auth/setClientName", clientName)
        await setUserPoolConfigurationsToAuth()
      }
    } else {
      await setUserPoolConfigurationsToAuth()
    }

    if (!clientName || !clientUserPoolId || !clientUserPoolClientId) {
      store.commit("configurations/setSplashScreen", false)
      return "not-found"
    }
  }
}