import store from "@/plugins/vuex"
import { CHANNEL } from "@/constants/bread-crumbs/channel"
import { DOMAIN_CHANNEL } from "@/constants/bread-crumbs/domain-channel"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { BOOLEAN } from "../constants"
import { PAGES } from "../constants"

/**
 * This method will be called before navigating to channel page.
 * @param {*} to route to be navigated to.
 */
export const beforeChannel = async (to, from) => {
  if (from.name !== "channel" || !(PAGES.CHANNEL.includes(to.hash) || PAGES.CHANNEL.includes(from.hash))) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CHANNEL))
    if (to.name === "domain-channel")  {
      store.commit("shared/setBreadcrumbs", DOMAIN_CHANNEL(to))
    } else {
      store.commit("shared/setBreadcrumbs", CHANNEL(to))
    }
    store.commit("channels/setDraftPrivacyPolicyTranslations", new Object())
    store.commit("channels/setPrivacyPolicyTranslations", new Object())
    store.commit("channels/resetUpdatingChannel")
    store.commit("channels/resetChannelUpdated")
    const promisesArrayToBeResolved      = []
    const loadConfigurations             = store.dispatch("configurations/loadConfigurations")
    const loadTranslationPreferences     = store.dispatch("translationPreferences/loadTranslationPreferences")
    const loadDomains                    = store.dispatch("domains/loadDomains")
    const loadAvailableOrganisationCodes = store.dispatch("organisationCodes/loadAvailableOrganisationCodes")
    promisesArrayToBeResolved.push(
      loadConfigurations,
      loadTranslationPreferences,
      loadDomains,
      loadAvailableOrganisationCodes
    )

    await store.dispatch("channels/loadChannel", +to.params.id)
    const channels = store.getters["channels/channels"]
    const channel  = channels.find(channel => channel.id === +to.params.id)
    if (!channel && (!to.params.domainId || channel.domainId !== +to.params.domainId)) {
      return {
        name: "not-found"
      }
    }

    if (channel.privacyPolicyText || channel.draftPrivacyPolicyText) {
      const loadPrivacyPolicyTranslationPromise = store.dispatch("channels/loadPrivacyPolicyTranslations", {
        id    : channel.id,
        draft : true,
        actual: true
      })
      promisesArrayToBeResolved.push(loadPrivacyPolicyTranslationPromise)
    }
    const  loadChannelFormTemplates      = store.dispatch("channels/loadChannelFormTemplates", +to.params.id)
    const  loadFormTemplates             = store.dispatch("formTemplates/loadFormTemplates", {
      reportForm: BOOLEAN.TRUE
    })
    const loadFieldsV2                   = store.dispatch("fields/loadFieldsV2")
    const loadFormTemplateConfigurations = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
    const  loadTranslationConfigurations = store.dispatch("channels/loadTranslationConfigurations", channel.id)
    promisesArrayToBeResolved.push(
      loadChannelFormTemplates,
      loadFormTemplates,
      loadFieldsV2,
      loadFormTemplateConfigurations,
      loadTranslationConfigurations
    )
    Promise.all(promisesArrayToBeResolved).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }
}
