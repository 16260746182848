import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to exceptions page.
 */
export const beforeExceptions = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.EXCEPTIONS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadExceptions   = store.dispatch("exceptions/loadExceptions")
  const loadChannels     = store.dispatch("channels/loadChannels")
  const loadTranslations = store.dispatch("translations/loadTranslations")
  const loadLanguages    = store.dispatch("languages/loadLanguages")
  Promise.all([
    loadExceptions,
    loadChannels,
    loadTranslations,
    loadLanguages
  ])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}