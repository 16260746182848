import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeScreens = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.SCREENS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadScreens   = store.dispatch("screens/loadScreens")
  const loadWorkflows = store.dispatch("workflows/loadWorkflows")
  Promise.all([loadScreens, loadWorkflows])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}