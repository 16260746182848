export default {
  setAiFeedback: (state, aiFeedback) => {
    state.aiFeedback = aiFeedback
  },
  setSubmittingAiFeedback: (state, submittingAiFeedback) => {
    state.submittingAiFeedback = submittingAiFeedback
  },
  setAiFeedbackSubmitted: (state, aiFeedbackSubmitted) => {
    state.aiFeedbackSubmitted = aiFeedbackSubmitted
    if (aiFeedbackSubmitted) {
      setTimeout(() => {
        state.aiFeedbackSubmitted = false
      }, 5000)
    }
  },
  setUpdatingAiFeedback: (state, updatingAiFeedback) => {
    state.updatingAiFeedback = updatingAiFeedback
  },
  setAiFeedbackUpdated: (state, aiFeedbackUpdated) => {
    state.aiFeedbackUpdated = aiFeedbackUpdated
    if (aiFeedbackUpdated) {
      setTimeout(() => {
        state.aiFeedbackUpdated = false
      }, 5000)
    }
  }
}