import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to users page.
 */
export const beforeUsers = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USERS))
  store.commit("shared/setBreadcrumbs", undefined)
  const promises               = []
  const groupPromisesToResolve = []
  const loadGroups             = store.dispatch("groups/loadGroups")
  groupPromisesToResolve.push(loadGroups)
  const loadUsersPromise = store.dispatch("users/loadUsers")
  promises.push(loadUsersPromise)
  Promise.all(promises)
    .then(() => {
      const users = store.getters["users/users"]
      for (const user of users) {
        const groupPromise = store.dispatch("users/loadGroups", user.id)
        groupPromisesToResolve.push(groupPromise)
      }
      return Promise.all(groupPromisesToResolve)
    })
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}