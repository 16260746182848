import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to option lists page.
 */
export const beforeOptionLists = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.OPTION_LISTS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadOptionLists     = store.dispatch("optionLists/loadOptionLists")
  const loadOptionListItems = store.dispatch("optionListItems/loadOptionListItems")
  Promise.all([loadOptionListItems, loadOptionLists])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}