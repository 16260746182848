import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/feedback"

export default {
  /**
   * this action will take the feedback for the Ai support.
   * @param {*} context is the store.
   * @param {*} payload is the feedback to be added.
   */
  async addAiFeedback(context, payload) {
    context.commit("setSubmittingAiFeedback", true)
    context.commit("setAiFeedbackSubmitted", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postAiFeedbackResponse = await httpClient.post(undefined, payload)
    if (postAiFeedbackResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiFeedback", postAiFeedbackResponse.data)
      context.commit("setAiFeedbackSubmitted", true)
    }
    context.commit("setSubmittingAiFeedback", false)
  },

  /**
   * This action will update the feedback for the Ai support.
   * @param {*} context is the store.
   * @param {*} payload contains the UUID and feedback data.
   */
  async updateAiFeedback(context, payload) {
    const uuid = payload.uuid
    delete payload.uuid
    context.commit("setUpdatingAiFeedback", true)
    context.commit("setAiFeedbackUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateAiFeedbackResponse = await httpClient.put(`/${uuid}`, payload)

    if (updateAiFeedbackResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setAiFeedback", payload)
      context.commit("setAiFeedbackUpdated", true)
    }

    context.commit("setUpdatingAiFeedback", false)
  },

  /* This action is used to reset store.
  * @param {*} context is the store.
  */
  reset(context) {
    context.commit("setAiFeedback", new Object())
    context.commit("setSubmittingAiFeedback", false)
    context.commit("setAiFeedbackSubmitted", false)
    context.commit("setUpdatingAiFeedback", false)
    context.commit("setAiFeedbackUpdated", false)
  }
}