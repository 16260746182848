export default {
  setLoadingFormInstances: (state, loadingFormInstances) => {
    state.loadingFormInstances = loadingFormInstances
  },
  setFormInstances: (state, formInstances) => {
    state.formInstances = formInstances
  },
  updateFormInstances: (state, formInstances) => {
    for (const formInstance of formInstances) {
      const index = state.formInstances.findIndex(stateFormInstances => stateFormInstances?.id === formInstance.id)
      if (index >= 0) {
        state.formInstances.splice(index, 1, { ...state.formInstances[index], ...formInstance })
      } else {
        state.formInstances.push(formInstance)
      }
    }
  },
  setAddingIssueFormInstance: (state, addingIssueFormInstance) => {
    state.addingIssueFormInstance = addingIssueFormInstance
  },
  setIssueFormInstanceAdded: (state, issueFormInstanceAdded) => {
    state.issueFormInstanceAdded = issueFormInstanceAdded
  },
  setUpdatingFormInstance: (state, updatingFormInstance) => {
    state.updatingFormInstance = updatingFormInstance
  },
  setFormInstanceUpdated: (state, formInstanceUpdated) => {
    state.formInstanceUpdated = formInstanceUpdated
  },
  setRemovingFormInstance: (state, removingFormInstance) => {
    state.removingFormInstance = removingFormInstance
  },
  setFormInstanceRemoved: (state, formInstanceRemoved) => {
    state.formInstanceRemoved = formInstanceRemoved
  },
  formInstanceRemove: (state, formInstanceToBeRemovedId) => {
    const index = state.formInstances.
      findIndex(formInstance =>formInstance.id === formInstanceToBeRemovedId
      )
    state.formInstances.splice(index, 1)
  },
  updateFormInstance: (state, payload) => {
    const formInstance = state.formInstances.find(item => item.id === payload.id)
    if (formInstance) {
      for (const formInstanceField of formInstance.formInstanceFields) {
        const formInstanceFieldValues = payload.data.filter(item =>
          item.formInstanceFieldId === formInstanceField.id
        )
        if (formInstanceFieldValues.length) {
          formInstanceField.formInstanceFieldValues = formInstanceField.formInstanceFieldValues.map(existingValue => {
            const newValue = formInstanceFieldValues.find(item =>
              item.formInstanceFieldId === existingValue.formInstanceFieldId)
            return newValue ? { ...existingValue, value: newValue.value } : existingValue
          })
        }
      }
    }
  }
}