export default {
  setLoadingIssueFormTemplates: (state, loadingIssueFormTemplates) => {
    state.loadingIssueFormTemplates = loadingIssueFormTemplates
  },
  setIssueFormTemplates: (state, issueFormTemplates) => {
    state.issueFormTemplates = issueFormTemplates
  },
  setAddingIssueFormTemplates: (state, addingIssueFormTemplates) => {
    state.addingIssueFormTemplates = addingIssueFormTemplates
  },
  setIssueFormTemplatesAdded: (state, issueFormTemplatesAdded) => {
    state.issueFormTemplatesAdded = issueFormTemplatesAdded
  },
  setIssueFormTemplateUpdateError: (state, issueFormTemplateUpdateError) => {
    state.issueFormTemplateUpdateError = issueFormTemplateUpdateError
    if (issueFormTemplateUpdateError) {
      setTimeout(() => {
        state.issueFormTemplateUpdateError = false
      }, 5000)
    }
  },
  updateIssueFormTemplates: (state, issueFormTemplates) => {
    for (const issueFormTemplate of issueFormTemplates) {
      const indexOfIssueFormTemplate = state.issueFormTemplates.findIndex(stateIssueFormTemplate =>
        stateIssueFormTemplate.id === issueFormTemplate.id
      )
      const issueFormTemplateInStore = state.issueFormTemplates[indexOfIssueFormTemplate]

      const issueFormTemplatesToBeUpdated = [{
        index: indexOfIssueFormTemplate,
        issueFormTemplate
      }]

      if (issueFormTemplateInStore && issueFormTemplate.sortingOrder) {
        for (const [index, stateIssueFormTemplate] of state.issueFormTemplates.entries()) {
          if (stateIssueFormTemplate.id !== issueFormTemplate.id) {
            if (stateIssueFormTemplate.sortingOrder < issueFormTemplateInStore.sortingOrder) {
              if (stateIssueFormTemplate.sortingOrder >= issueFormTemplate.sortingOrder) {
                issueFormTemplatesToBeUpdated.push({
                  index,
                  issueFormTemplate: {
                    sortingOrder: stateIssueFormTemplate.sortingOrder + 1
                  }
                })
              }
            } else if (stateIssueFormTemplate.sortingOrder > issueFormTemplateInStore.sortingOrder) {
              if (stateIssueFormTemplate.sortingOrder <= issueFormTemplate.sortingOrder) {
                issueFormTemplatesToBeUpdated.push({
                  index,
                  issueFormTemplate: {
                    sortingOrder: stateIssueFormTemplate.sortingOrder - 1
                  }
                })
              }
            }
          }
        }
      }

      for (const issueFormTemplateToBeUpdated of issueFormTemplatesToBeUpdated) {
        if (issueFormTemplateToBeUpdated.index >= 0) {
          state.issueFormTemplates.splice(issueFormTemplateToBeUpdated.index, 1, {
            ...state.issueFormTemplates[issueFormTemplateToBeUpdated.index],
            ...issueFormTemplateToBeUpdated.issueFormTemplate
          })
        } else {
          state.issueFormTemplates.push(issueFormTemplateToBeUpdated.issueFormTemplate)
        }
      }
    }
  },
  setUpdatingIssueFormTemplate: (state, properties) => {
    for (const property of properties) {
      state.updatingIssueFormTemplate[property] = true
    }
  },
  setIssueFormTemplateUpdated: (state, properties) => {
    for (const property of properties) {
      state.issueFormTemplateUpdated[property] = true
      setTimeout(() => {
        state.issueFormTemplateUpdated[property] = false
      }, 5000)
    }
  },
  setRemovingIssueFormTemplate: (state, removingIssueFormTemplate) => {
    state.removingIssueFormTemplate = removingIssueFormTemplate
  },
  setIssueFormTemplateRemoved: (state, issueFormTemplateRemoved) => {
    state.issueFormTemplateRemoved = issueFormTemplateRemoved
  },
  issueFormTemplateRemove: (state, issueFormTemplateToBeRemovedId) => {
    const index = state.issueFormTemplates.
      findIndex(issueFormTemplate =>issueFormTemplate.id === issueFormTemplateToBeRemovedId
      )
    state.issueFormTemplates.splice(index, 1)
  },
  resetIssueFormTemplateUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.issueFormTemplateUpdated[property] = false
      }
    } else {
      Object.keys(state.issueFormTemplateUpdated).forEach(
        property => state.issueFormTemplateUpdated[property] = false
      )
    }
  },
  resetUpdatingIssueFormTemplate: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingIssueFormTemplate[property] = false
      }
    } else {
      Object.keys(state.updatingIssueFormTemplate).forEach(
        property => state.updatingIssueFormTemplate[property] = false
      )
    }
  }
}