import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to channels add page
 */
export const beforeChannelAdd = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CHANNEL_ADD))
  store.commit("shared/setBreadcrumbs", undefined)
  store.commit("channels/setChannelAdded", undefined)
  store.commit("channels/setAddingChannel", undefined)
  store.commit("channels/setChannelAddError", undefined)
  const loadDomains                    = store.dispatch("domains/loadDomains")
  const loadAvailableOrganisationCodes =  store.dispatch("organisationCodes/loadAvailableOrganisationCodes")
  const loadConfigurations             = store.dispatch("configurations/loadConfigurations")
  const loadTranslationPreferences     = store.dispatch("translationPreferences/loadTranslationPreferences")
  Promise.all([
    loadDomains,
    loadAvailableOrganisationCodes,
    loadConfigurations,
    loadTranslationPreferences
  ]).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}