import { defaultTableHeaderClasses } from "@/constants"

export const ROLE_ADD_PERMISSIONS = {
  headers: [{
    align: "left",
    value: "data-table-select",
    width: "40",
    class: defaultTableHeaderClasses
  }, {
    text    : "1862",
    value   : "permission",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }]
}