import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-comments"

export default {
  /**
   * This action will load issue comments.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load issue comments.
  */
  async loadIssueComments(context, payload) {
    context.commit("setIsLoadingIssueComments", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueCommentsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueCommentsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueComments", getIssueCommentsResponse.data)
      } else {
        context.commit("setIssueComments", getIssueCommentsResponse.data)
      }
    } else {
      // something went wrong while getting issueComments
    }

    context.commit("setIsLoadingIssueComments", false)
  },

  /**
   * This action will add a draft comment.
   * @param {*} context is the store.
   * @param {*} payload contains id of the issue and comment to be saved.
  */
  async addComment(context, payload) {
    context.commit("setCommentAdded", false)
    context.commit("setAddingComment", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postCommentsResponse = await httpClient.post(undefined, payload)

    if (postCommentsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateIssueComments", [postCommentsResponse.data])
      context.commit("setCommentAdded", true)
    } else {
      // something went wrong while adding a comment
    }
    context.commit("setAddingComment", false)
  },

  /**
   * This action will update a comment.
   * @param {*} context is the store
   * @param {*} payload contains id of the comment and comment to be updated.
   */
  async updateComment(context, payload) {
    context.commit("setCommentUpdated", false)
    context.commit("setUpdatingComment", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const dataToBeUpdated = {
      value          : payload.value,
      mentionComments: payload.mentionComments
    }

    if (payload.draft !== undefined) {
      dataToBeUpdated.draft = payload.draft
    }
    const updateCommentResponse = await httpClient.put(`/${payload.id}`, dataToBeUpdated)

    if (updateCommentResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateIssueComments", [payload])
      if (payload.draft === false) {
        context.dispatch("accessControl/loadLoggedInUserAccess", [{
          policies   : ["IssueComment remove"],
          resourceIds: [payload.id]
        }], { root: true })
      }
      context.commit("setCommentUpdated", true)
    } else {
      // something went wrong while updating a comment
    }
    context.commit("setUpdatingComment", false)
  },

  /**
   * This action will add a comment.
   * @param {*} context is the store.
  */
  async removeComment(context, payload) {
    context.commit("setCommentRemoved", false)
    context.commit("setRemovingComment", true)
    const commentId  = payload.id
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteCommentResponse = await httpClient.delete(`/${commentId}`)

    if (deleteCommentResponse?.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeCommentFromIssueComment", {
        commentId
      })
      if (payload?.draft !== true) {
        context.commit("setCommentRemoved", true)
      }
    } else {
      // something went wrong while adding a comment
    }

    context.commit("setRemovingComment", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
  */
  reset(context) {
    context.commit("setIssueComments", [])
    context.commit("setIsLoadingIssueComments", undefined)
    context.commit("setAddingComment", undefined)
    context.commit("setCommentAdded", undefined)
    context.commit("setCommentRemoved", undefined)
    context.commit("setRemovingComment", undefined)
  }
}