import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ISSUE_STATUS_CATEGORY } from "@/constants"

/**
 * This method will be called before navigating to dashboard
 */
export const beforeDashboard = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.DASHBOARD))
  store.commit("shared/setBreadcrumbs", undefined)
  let loadKPIs
  let loadIssues
  const promises              = []
  const loggedInUserRoleTypes = store.getters["auth/loggedInUserRoleTypes"]
  if (loggedInUserRoleTypes.includes("issue")) {
    loadKPIs = store.dispatch("kpis/loadKPIs")
    promises.push(loadKPIs)

    const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
    if (loggedInUserPolicies["Issue view"]) {
      const loggedInUser                         = store.getters["auth/loggedInUser"]
      const issueStatuses                        = store.getters["issueStatuses/issueStatuses"]
      const statusIdWithCategoryNewAndInprogress = issueStatuses
        .filter(status => status.category === ISSUE_STATUS_CATEGORY.NEW
          || status.category === ISSUE_STATUS_CATEGORY.IN_PROGRESS)
        .map(status => status.id)

      store.commit("issues/setIssues", [])
      loadIssues = store.dispatch("issues/loadIssues", {
        properties: "id,summary,typeId,dueDate,receivedAt,createdAt",
        assigneeId: loggedInUser.id,
        statusId  : statusIdWithCategoryNewAndInprogress.toString()
      })
      promises.push(loadIssues)
    }
  }
  Promise.all(promises).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}