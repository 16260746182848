export default {
  isLoadingFilters     : state => state.loadingFilters,
  filters              : state => state.filters,
  isAddingFilter       : state => state.addingFilter,
  isFilterAdded        : state => state.filterAdded,
  filterAddError       : state => state.filterAddError,
  isRemovingFilter     : state => state.removingFilter,
  isFilterRemoved      : state => state.filterRemoved,
  isUpdatingName       : state => state.updatingFilter.name,
  isNameUpdated        : state => state.filterUpdated.name,
  filterNameUpdateError: state => state.filterUpdateError.name,
  isUpdatingAccess     : state => state.updatingFilter.access,
  isAccessUpdated      : state => state.filterUpdated.access,
  isLoadingFilter      : state => state.loadingFilter,
  isUpdatingCriteria   : state => state.updatingFilter.criteria,
  isCriteriaUpdated    : state => state.filterUpdated.criteria
}