import store from "@/plugins/vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { SCREEN } from "@/constants/bread-crumbs/screen"

export const beforeScreen = async (to, from) => {
  if ((from.name === "screen" || from.name === "screen-item-side-panel") &&
    to.name === "screen-item-side-panel") {
    return
  } else if (from.name === "screen-item-side-panel" && to.name === "screen") {
    return
  } else {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    await store.dispatch("screens/loadScreen", +to.params.id)
    const screens = store.getters["screens/screens"]
    const screen  = screens.find(screen => screen.id === +to.params.id)
    if (!screen) {
      return "not-found"
    }
    const loadScreenItemsPromise = store.dispatch("screens/loadScreenItems", {
      screenId: +to.params.id
    })

    if (to.name === "screen-item-side-panel") {
      await loadScreenItemsPromise
      const screenItems = store.getters["screens/screenItems"]
      const screenItem  = screenItems.find(item => item.id === +to.params.itemId)
      if (!screenItem) {
        return "not-found"
      }
    }
    const loadIssueFields = store.dispatch("issueFields/loadIssueFields")
    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(screen.name))
    store.commit("shared/setBreadcrumbs", SCREEN(to, screen.name))
    const loadOptionLists = store.dispatch("optionLists/loadOptionLists")
    const loadFieldsV2    = store.dispatch("fields/loadFieldsV2")
    Promise.all([loadIssueFields, loadOptionLists, loadFieldsV2])
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  }
}