export default {
  loadingConfigurations: undefined,
  configurations       : new Array(),
  updatingConfiguration: {
    value: undefined
  },
  configurationUpdated: {
    value: undefined
  },
  configurationUpdateError: {
    value: undefined
  },
  enablingDefaultSsoLogin       : undefined,
  defaultSsoLoginEnabled        : undefined,
  loadingDefaultSsoConfiguration: undefined,
  defaultSsoConfiguration       : undefined,
  savingDefaultSsoConfiguration : undefined,
  defaultSsoConfigurationSaved  : undefined,
  defaultSsoConfigurationError  : undefined,
  splashScreen                  : false
}