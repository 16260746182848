/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import CONSTANTS from "@/constants"
import store from "@/plugins/vuex"
export default {
  /**
   * This action will notify users
   * @param {*} context is the store.
   * @param {*} notification is the notification content.
   */
  async notify(context, notification) {
    if (!context.getters.showingPermanentNotification || notification.retainSnackbar) {
      context.commit("setNotify", true)
      context.commit("setNotification", notification)

      if (!notification.retainSnackbar) {
        const notificationTimeout = setTimeout(() => {
          context.commit("setNotify", false)
          context.commit("setNotification", undefined)
        }, CONSTANTS.NOTIFICATION_TIMEOUT)
        context.commit("setNotificationTimeout", notificationTimeout)
      } else {
        const notificationTimeout = context.getters.notificationTimeout
        clearTimeout(notificationTimeout)
        context.commit("setNotificationTimeout", undefined)
        context.commit("setPermanentNotification", true)
      }
    }
  },

  /**
   * This action will suppress notification.
   * @param {*} context is the store.
   */
  suppressNotification(context) {
    context.commit("setNotify", false)
    context.commit("setNotification", undefined)
  },

  startLogoutInterval(context) {
    const interval = setInterval(() => {
      const currentTime   = new Date().getTime()
      const remainingTime = store.getters["auth/logOutTime"] - currentTime
      context.commit("setRemainingTime", remainingTime)
      if (remainingTime <= CONSTANTS.MILLISECONDS_TO_SHOW_LOGOUT_NOTIFICATION) {
        context.commit("setTokenIsAboutToExpire", true)
        context.dispatch("startCountdown", remainingTime / CONSTANTS.MILLISECONDS_IN_A_SECOND)
        clearInterval(interval)
        context.commit("setStartInterval", null)
      }
    }, CONSTANTS.MILLISECONDS_IN_A_SECOND)
    context.commit("setStartInterval", interval)
  },

  startCountdown(context, duration) {
    const interval = setInterval(() => {
      const minutes       = Math.floor(duration / CONSTANTS.SECONDS_IN_A_MINUTE)
      const seconds       = Math.floor(duration % CONSTANTS.SECONDS_IN_A_MINUTE)
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
      if (duration > 0) {
        duration -= 1
        context.dispatch("notify", {
          text          : "2047",
          type          : "warning",
          retainSnackbar: true,
          parameters    : {
            time: formattedTime
          }
        })
      } else {
        clearInterval(context.state.countdownInterval)
        context.commit("setCountdownInterval", null)
        store.dispatch("auth/logout")
      }
    }, CONSTANTS.MILLISECONDS_IN_A_SECOND)
    context.commit("setCountdownInterval", interval)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setBreadcrumbs", undefined)
    context.commit("setPageTitle", undefined)
    context.commit("setPageFreezed", false)
    context.commit("setNotify", false)
    context.commit("setNotFoundError", undefined)
    context.commit("setForbiddenError", undefined),
    context.commit("setProgressBarInitiated", false),
    context.commit("setProgressBarPromisesPending", false),
    context.commit("setPermanentNotification", false)
    context.commit("setNotificationTimeout", undefined)
    context.commit("setTokenIsAboutToExpire", false)
    clearInterval(context.state.countdownInterval)
    clearInterval(context.state.startInterval)
    context.commit("setStartInterval", null)
    context.commit("setCountdownInterval", null)
    context.commit("setRemainingTime", null)
  }
}