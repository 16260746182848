import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DATA_EXPORT_TYPE } from "@/constants"
import { PAGES } from "@/constants"

/**
* This method will be called before navigating to Analytics page.
*/
export const beforeAnalytics = async (to, from)  => {
  const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
  if (!loggedInUserPolicies["Analytics view"]) {
    return "not-found"
  }

  if (from.name !== "analytics" || !(PAGES.ANALYTICS.includes(to.hash) || PAGES.ANALYTICS.includes(from.hash))) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)
    const promisesArrayToBeResolved = []
    const loadKPIs                  = store.dispatch("kpis/loadKPIs")
    promisesArrayToBeResolved.push(loadKPIs)

    if (loggedInUserPolicies["Export view"]) {
      const loadExportsPromise = store.dispatch("exports/loadExports", { type: DATA_EXPORT_TYPE.ANALYTICS })
      promisesArrayToBeResolved.push(loadExportsPromise)
    }

    if (loggedInUserPolicies["Domain view"]) {
      const domainsPromise = store.dispatch("domains/loadDomains")
      promisesArrayToBeResolved.push(domainsPromise)
    }
    if (loggedInUserPolicies["Channel view"]) {
      const loadChannelsPromise = store.dispatch("channels/loadChannels")
      promisesArrayToBeResolved.push(loadChannelsPromise)
    }
    if (loggedInUserPolicies["Language view"]) {
      const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
      promisesArrayToBeResolved.push(loadLanguagesPromise)
    }
    Promise.all(promisesArrayToBeResolved).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }
}