import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { USER } from "@/constants/bread-crumbs/user"

/**
 * This method will be called before navigating to user page.
 * @param {*} to route to be navigated to.
 */
export const beforeUser = async to => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USER))
  store.commit("shared/setBreadcrumbs", USER(to))
  await store.dispatch("groups/loadGroups")
  await store.dispatch("users/loadUser", +to.params.id)
  const users = store.getters["users/users"]
  const user  = users.find(user => user.id === +to.params.id)
  if (!user) {
    return "not-found"
  }
  store.dispatch("users/loadGroups", +to.params.id)
  store.commit("users/resetUserUpdated")
  store.commit("users/resetUpdatingUser")
  store.commit("users/resetUserUpdateError")
  store.commit("shared/setProgressBarInitiated", false)
  store.commit("shared/setProgressBarPromisesPending", false)
}