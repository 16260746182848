import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to exceptions page.
 */
export const beforeLogs = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.LOGS))
  store.commit("shared/setBreadcrumbs", undefined)
  const loadUsers  = store.dispatch("users/loadUsers")
  const loadGroups = store.dispatch("groups/loadGroups")
  const loadRoles  = store.dispatch("roles/loadRoles")
  Promise.all([loadUsers, loadGroups, loadRoles ])
    .then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
}