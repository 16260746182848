import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeFilters = async () => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)

  store.commit("shared/setBreadcrumbs", undefined)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ALL_FILTERS))

  const progressBarPromisesArray = []
  const dependentPromisesArray   = []
  const loggedInUserPolicies     = store.getters["accessControl/loggedInUserPolicies"]
  if (loggedInUserPolicies["SavedFilter view"]) {
    const loadFiltersPromise = store.dispatch("filters/loadFilters")
    const loadUsersPromise   = store.dispatch("users/loadUsers")
    const loadGroupsPromise  = store.dispatch("groups/loadGroups")

    progressBarPromisesArray.push(
      loadUsersPromise,
      loadGroupsPromise,
      loadFiltersPromise
    )
    loadFiltersPromise.then(() => {
      const filters = store.getters["filters/filters"]
      return store.dispatch("accessControl/loadLoggedInUserAccess", [{
        policies: [
          "SavedFilter remove",
          "SavedFilter update"
        ],
        resourceIds: filters.map(filter => filter.id)
      }])
    })
    loadFiltersPromise.then(savedFilterPolicy => {
      dependentPromisesArray.push(savedFilterPolicy)
    })

    const resolvePromisesSequentially = promises => {
      let promiseChain = Promise.resolve()

      for (const promise of promises) {
        promiseChain = promiseChain.then(() => promise)
      }

      return promiseChain
    }

    resolvePromisesSequentially(progressBarPromisesArray)
      .then(() => {
        return resolvePromisesSequentially(dependentPromisesArray)
      })
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  } else {
    return {
      name: "not-found"
    }
  }
}
