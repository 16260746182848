import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { PAGES } from "@/constants"

export const beforeUserSettings = async (to, from) => {
  if (from.name !== "user-settings" || !(PAGES.USER_SETTINGS.includes(to.hash) || PAGES.USER_SETTINGS.includes(from.hash))) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    const promisesArrayToBeResolved = []
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.USER_SETTINGS))
    const emailSubscriptionsPromise = store.dispatch("emailSubscriptions/loadEmailSubscriptions")
    promisesArrayToBeResolved.push(emailSubscriptionsPromise)
    store.commit("shared/setBreadcrumbs", undefined)
    const isDefaultSsoEnabled = await store.getters["configurations/isDefaultSsoEnabled"]

    if(!isDefaultSsoEnabled) {
      store.commit("auth/setVerifyingToken", false)
      store.commit("auth/setTokenVerified", undefined)
      const loadUserDataPromise = store.dispatch("auth/loadUserData")
      promisesArrayToBeResolved.push(loadUserDataPromise)
    }
    await emailSubscriptionsPromise
    Promise.all(promisesArrayToBeResolved).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }
}