import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { BOOLEAN } from "@/constants"

/**
* This method will be called before navigating to issue fields and forms page.
*/
export const beforeIssueFieldsAndForms = async (to, from) => {
  if (!["issue-fields", "issue-field", "issue-form-templates", "issue-form-template" ].includes(from.name)) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_FIELDS))
    store.commit("shared/setBreadcrumbs", undefined)

    const issueFieldPromise        = store.dispatch("issueFields/loadIssueFields")
    const issueFormTemplatePromise = store.dispatch("issueFormTemplates/loadIssueFormTemplates")

    if (to.name === "issue-field") {
      await issueFieldPromise
      const issueFields = store.getters["issueFields/issueFields"]
      const issueField  = issueFields.find(issueField => issueField.id === +to.params.id)
      if (!issueField) {
        return "not-found"
      }
    } else if (to.name === "issue-form-template") {
      await issueFormTemplatePromise
      const issueFormTemplates = store.getters["issueFormTemplates/issueFormTemplates"]
      const issueFormTemplate  = issueFormTemplates.find(issueFormTemplate => issueFormTemplate.id === +to.params.id)
      if (!issueFormTemplate) {
        return "not-found"
      }
    }
    const promiseToBeResolved      = []
    const loadFieldsV2             = store.dispatch("fields/loadFieldsV2")
    const loadOptionLists          = store.dispatch("optionLists/loadOptionLists")
    const loadFormTemplatesPromise = store.dispatch("formTemplates/loadFormTemplates", {
      reportForm: BOOLEAN.FALSE
    }).then(() => {
      const issueFormTemplates = store.getters["formTemplates/formTemplates"]
      if (issueFormTemplates.length) {
        const loadFormTemplateConfigurationsPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
          formTemplateId: issueFormTemplates.map(formTemplate => formTemplate.id).toString()
        })
        promiseToBeResolved.push(loadFormTemplateConfigurationsPromise)
      }
    })
    Promise.all([loadFieldsV2, loadOptionLists, loadFormTemplatesPromise, issueFormTemplatePromise, issueFieldPromise])
      .then(() => {
        return Promise.all(promiseToBeResolved)
      })
      .then(() => {
        store.commit("shared/setProgressBarPromisesPending", false)
        store.commit("shared/setProgressBarInitiated", false)
      })
  }
}
