export default {
  loadingIssueFormTemplates   : undefined,
  issueFormTemplateUpdateError: undefined,
  issueFormTemplates          : new Array(),
  addingIssueFormTemplates    : false,
  issueFormTemplatesAdded     : false,
  removingIssueFormTemplate   : false,
  issueFormTemplateRemoved    : false,
  updatingIssueFormTemplate   : {
    multiInstance: false,
    autoAdded    : false,
    sortingOrder : false
  },
  issueFormTemplateUpdated: {
    multiInstance: false,
    autoAdded    : false,
    sortingOrder : false
  }
}