import { defaultTableHeaderClasses } from "@/constants"

export const ISSUE_FORM_TEMPLATE_ADD_FOR_ISSUE_TYPE = {
  headers: [
    {
      align: "left",
      value: "data-table-select",
      width: "40",
      class: defaultTableHeaderClasses
    },
    {
      text : "2111",
      align: "left",
      value: "name",
      class: defaultTableHeaderClasses
    },
    {
      text : "2112",
      align: "left",
      value: "fields",
      class: defaultTableHeaderClasses
    }
  ]
}