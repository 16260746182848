/**
 * @file It contains all the vuex token module state variables.
 */
export default {
  pageFreeze     : false,
  notify         : false,
  configurations : undefined,
  clientName     : undefined,
  breadcrumbs    : undefined,
  notification   : undefined,
  unexpectedError: undefined,
  notFoundError  : undefined,
  forbiddenError : undefined,
  logout         : undefined,
  pageTitle      : {
    value         : undefined,
    useTranslation: false
  },
  isProgressBarInitiated      : false,
  isProgressBarPromisesPending: false,
  permanentNotification       : false,
  notificationTimeout         : undefined,
  startInterval               : null,
  countdownInterval           : null,
  remainingTime               : null,
  tokenIsAboutToExpire        : false
}
