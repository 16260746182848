/**
 * @file It contains vuex token module getter methods to access store variables.
 */
export default {
  isPageFreezed               : state => state.pageFreeze,
  isNotified                  : state => state.notify,
  configurations              : state => state.configurations,
  clientName                  : state => state.clientName,
  breadcrumbs                 : state => state.breadcrumbs,
  notification                : state => state.notification,
  unexpectedError             : state => state.unexpectedError,
  pageTitle                   : state => state.pageTitle,
  notFoundError               : state => state.notFoundError,
  forbiddenError              : state => state.forbiddenError,
  isProgressBarInitiated      : state => state.isProgressBarInitiated,
  isProgressBarPromisesPending: state => state.isProgressBarPromisesPending,
  showingPermanentNotification: state => state.permanentNotification,
  notificationTimeout         : state => state.notificationTimeout,
  startInterval               : state => state.startInterval,
  countdownInterval           : state => state.countdownInterval,
  remainingTime               : state => state.remainingTime,
  tokenIsAboutToExpire        : state => state.tokenIsAboutToExpire
}