export default {
  setAddingFormInstanceFieldValues(state, addingFormInstanceFieldValues) {
    state.addingFormInstanceFieldValues = addingFormInstanceFieldValues
  },
  setFormInstanceFieldValuesAdded(state, formInstanceFieldValuesAdded) {
    state.formInstanceFieldValuesAdded = formInstanceFieldValuesAdded
  },
  updateFormInstanceFieldValues: (state, formInstanceFieldValues) => {
    for (const formInstanceFieldValue of formInstanceFieldValues) {
      const index = state.formInstanceFieldValues.findIndex(stateFormInstanceFieldValue =>
        stateFormInstanceFieldValue?.id === formInstanceFieldValue.id)
      if (index >= 0) {
        state.formInstanceFieldValues.splice(index, 1,
          { ...state.formInstanceFieldValues[index], ...formInstanceFieldValue })
      } else {
        state.formInstanceFieldValues.push(formInstanceFieldValue)
      }
    }
  }
}