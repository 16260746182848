import { defaultTableHeaderClasses } from "@/constants"

export const ISSUE_FORM_TEMPLATE_FOR_ISSUE_TYPE = {
  headers: [{
    value: "sortingOrder",
    class: defaultTableHeaderClasses,
    width: "50"
  },
  {
    text : "2111",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "2112",
    align: "left",
    value: "fields",
    class: defaultTableHeaderClasses
  },
  {
    value   : "actions",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }]
}