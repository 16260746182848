import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const FILTERS = {
  headers: [{
    text : "2156",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "2157",
    align: "left",
    value: "creatorId",
    class: defaultTableHeaderClasses
  },
  {
    text : "2158",
    align: "left",
    value: "sharedWith",
    class: defaultTableHeaderClasses
  },
  {
    value   : "actions",
    align   : "right",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  noResultText: "2164",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}