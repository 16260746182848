import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeFields = async (to, from) => {

  let loadFieldsPromise
  if (!["fields", "field"].includes(from.name)) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.FIELDS))
    store.commit("shared/setBreadcrumbs", undefined)
    loadFieldsPromise            = store.dispatch("fields/loadFieldsV2")
    const loadOptionLists        = store.dispatch("optionLists/loadOptionLists")
    const loadDataRetentionRules = store.dispatch("dataRetentionRules/loadDataRetentionRules")
    const loadConfigurations     = store.dispatch("configurations/loadConfigurations")
    Promise.all([loadFieldsPromise,
      loadOptionLists,
      loadDataRetentionRules,
      loadConfigurations ]).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }

  if (loadFieldsPromise && to.name === "field") {
    await loadFieldsPromise
  }

  if (to.name === "field") {
    const fieldsV2 = store.getters["fields/fieldsV2"]
    const field    = fieldsV2.find(field => field.id === +to.params.id)
    if (!field) {
      return "not-found"
    }
  }
}
