import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION, PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { FILTER } from "@/constants/bread-crumbs/filter"
import { DASHBOARD_WIDGET_FILTER_PREFERENCE, ISSUE_STATUS_CATEGORY } from "@/constants"
import moment from "moment"
import { VALID_DATE_FOR_INACTIVE_ISSUES_FILTER } from "../constants"

export const beforeIssues = async (to, from) => {
  const isSameRoute   = to.name === from.name
  const unixEpochTime = moment(new Date(0)).toISOString()

  if (!isSameRoute) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUES))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("transitions/setLastCreatedIssue", null)

    const loggedInUser = store.getters["auth/loggedInUser"]

    const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]

    if (from.name === "dashboard") {
      let issuesFiltersPreference

      if (to.query.unread) {
        issuesFiltersPreference = DASHBOARD_WIDGET_FILTER_PREFERENCE.UNREAD_MESSAGES
      } else if (to.query.unanswered) {
        issuesFiltersPreference = DASHBOARD_WIDGET_FILTER_PREFERENCE.UNANSWERED_MESSAGES
      } else if (to.query.dueSoon) {
        const currentDate  = moment(new Date()).utc().toISOString()
        const dueStartDate = moment(new Date()).utc().add(5, "days").toISOString()

        issuesFiltersPreference = {
          status: [{
            category: [
              ISSUE_STATUS_CATEGORY.NEW,
              ISSUE_STATUS_CATEGORY.IN_PROGRESS
            ]
          }],
          dueDate: `${currentDate}to${dueStartDate}`
        }
      } else if (to.query.overdue) {
        const currentDate      = moment(new Date()).utc().toISOString()
        const overdueStartDate = unixEpochTime

        issuesFiltersPreference = {
          status: [{
            category: [
              ISSUE_STATUS_CATEGORY.NEW,
              ISSUE_STATUS_CATEGORY.IN_PROGRESS
            ]
          }],
          dueDate: `${overdueStartDate}to${currentDate}`
        }
      } else if (to.query.inactive) {
        const currentDate     = moment(new Date()).utc().toISOString()
        const lastUpdatedDate = moment(currentDate).subtract(VALID_DATE_FOR_INACTIVE_ISSUES_FILTER, "days").utc().toISOString()

        issuesFiltersPreference = {
          status: [{
            category: [
              ISSUE_STATUS_CATEGORY.NEW,
              ISSUE_STATUS_CATEGORY.IN_PROGRESS
            ]
          }],
          updatedAt: `${unixEpochTime}to${lastUpdatedDate}`
        }
      }

      if (issuesFiltersPreference) {
        store.commit("users/updateUsers", [{
          id: loggedInUser.id,
          issuesFiltersPreference
        }])
      }
    }
    const progressBarPromisesArray = []
    const dependentPromisesArray   = []

    const loggedInUserIssuesFiltersPreference = store.getters["users/loggedInUserIssuesFiltersPreference"]
    let filter
    if (to.name === "filter" && to.params.filterId) {
      await store.dispatch("filters/loadFilter", { id: +to.params.filterId })
      const filters = store.getters["filters/filters"]
      filter        = filters.find(filter => filter.id === +to.params.filterId)
      if (filter) {
        store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(filter.name))
        store.commit("shared/setBreadcrumbs", FILTER(to, filter))
        const filterUpdatePolicyPromise = store.dispatch("accessControl/loadLoggedInUserAccess", [{
          policies   : ["SavedFilter update"],
          resourceIds: [+to.params.filterId]
        }])
        progressBarPromisesArray.push(filterUpdatePolicyPromise)
      } else {
        const isNotFoundError  = store.getters["shared/notFoundError"]
        const isForbiddenError = store.getters["shared/forbiddenError"]
        if (isNotFoundError) {
          return {
            name: "not-found"
          }
        } else if (isForbiddenError) {
          return {
            name: "forbidden"
          }
        }
      }
    }
    let loadIssueSearchPromise
    if (loggedInUserPolicies["IssueSearch add"]) {
      if (to.name === "filter" && to.params.filterId && filter) {
        loadIssueSearchPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
          filterId: +to.params.filterId
        })
      } else {
        loadIssueSearchPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
          criteria: {
            searchText               : null,
            issuesFilters            : loggedInUserIssuesFiltersPreference ?? {},
            searchThroughAllIssueData: false
          }
        })
      }

      progressBarPromisesArray.push(loadIssueSearchPromise)
    }

    if (loadIssueSearchPromise) {
      loadIssueSearchPromise.then(() => {
        if (loggedInUserPolicies["User view"]) {
          const loadUsersPromise = store.dispatch("users/loadUsers")
          progressBarPromisesArray.push(loadUsersPromise)
        }
      })
    }

    if (loggedInUserPolicies["IssueSearch view"] && loggedInUserPolicies["Issue view"]) {
      const loadIssuesPromise = store.dispatch("issues/loadIssues", {
        properties: "id"
      })
      progressBarPromisesArray.push(loadIssuesPromise)
    }

    if (loggedInUserPolicies["FieldV2 view"]) {
      const loadFieldsV2Promise = store.dispatch("fields/loadFieldsV2")
      progressBarPromisesArray.push(loadFieldsV2Promise)
    }

    if (loggedInUserPolicies["User view groups"]) {
      const loadGroupsPromise = store.dispatch("users/loadGroups", loggedInUser.id)
      progressBarPromisesArray.push(loadGroupsPromise)
    }

    if (loggedInUserPolicies["Access view"]) {
      const loadAccessesPromise = store.dispatch("accesses/loadAccesses")
      progressBarPromisesArray.push(loadAccessesPromise)
    }

    if (loggedInUserPolicies["IssueField view"]) {
      const loadIssueFieldsPromise = store.dispatch("issueFields/loadIssueFields")
      progressBarPromisesArray.push(loadIssueFieldsPromise)
    }

    if (loggedInUserPolicies["IssueFieldValue view"]) {
      const loadIssueFieldValuesPromise = store.dispatch("issueFieldValues/loadIssueFieldValues")
      progressBarPromisesArray.push(loadIssueFieldValuesPromise)
    }

    if (loggedInUserPolicies["Domain view"]) {
      const loadDomainsPromise = store.dispatch("domains/loadDomains")
      progressBarPromisesArray.push(loadDomainsPromise)
    }

    if (loggedInUserPolicies["Label view"]) {
      const loadLabelsPromise = store.dispatch("labels/loadLabels")
      progressBarPromisesArray.push(loadLabelsPromise)
    }

    if (loggedInUserPolicies["OptionListItem view"]) {
      const loadOptionListItemsPromise = store.dispatch("optionListItems/loadOptionListItems")
      progressBarPromisesArray.push(loadOptionListItemsPromise)
    }

    if (loggedInUserPolicies["Channel view"]) {
      const loadChannelsPromise = store.dispatch("channels/loadChannels")
      progressBarPromisesArray.push(loadChannelsPromise)
    }

    if (loggedInUserPolicies["IssueStatus view"]) {
      const loadIssueStatusesPromise = store.dispatch("issueStatuses/loadIssueStatuses")
      progressBarPromisesArray.push(loadIssueStatusesPromise)
    }

    if (loggedInUserPolicies["IssueResolution view"]) {
      const loadIssueResolutionsPromise = store.dispatch("issueResolutions/loadIssueResolutions")
      progressBarPromisesArray.push(loadIssueResolutionsPromise)
    }

    if (loggedInUserPolicies["Analytics view"]) {
      const loadKPIsPromise = store.dispatch("kpis/loadKPIs")
      progressBarPromisesArray.push(loadKPIsPromise)
    }

    if (loggedInUserPolicies["Language view"]) {
      const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
      progressBarPromisesArray.push(loadLanguagesPromise)
    }

    if (loggedInUserPolicies["FormTemplate view"]) {
      const loadFormTemplatesPromise = store.dispatch("formTemplates/loadFormTemplates").then(() => {
        const formTemplates = store.getters["formTemplates/formTemplates"]
        if (formTemplates.length) {
          const loadFormTemplateConfigurationPromise = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
            formTemplateId: formTemplates.map(formTemplate => formTemplate.id).toString()
          })
          dependentPromisesArray.push(loadFormTemplateConfigurationPromise)
        }
      })
      progressBarPromisesArray.push(loadFormTemplatesPromise)
    }

    if(loggedInUserPolicies["IssueFormTemplate view"]) {
      const loadIssueFormTemplatesForIssueTypesPromise = store.dispatch("issueFormTemplates/loadIssueFormTemplates")
      progressBarPromisesArray.push(loadIssueFormTemplatesForIssueTypesPromise)
    }

    const resolvePromisesSequentially = promises => {
      let promiseChain = Promise.resolve()

      for (const promise of promises) {
        promiseChain = promiseChain.then(() => promise)
      }

      return promiseChain
    }

    resolvePromisesSequentially(progressBarPromisesArray)
      .then(() => {
        return resolvePromisesSequentially(dependentPromisesArray)
      })
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        if (!loggedInUserPolicies["IssueSearch view"]) {
          store.commit("shared/setProgressBarPromisesPending", false)
        }
      })
  }
}
