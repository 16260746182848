export default {
  setIsLoadingIssueComments(state, loadingIssueComments) {
    state.loadingIssueComments = loadingIssueComments
  },
  updateIssueComments: (state, issueComments) => {
    for (const issueComment of issueComments) {
      const index = state.issueComments.findIndex(stateIssueComment => stateIssueComment?.id === issueComment.id)
      if (index >= 0) {
        const updatedComment = { ...state.issueComments[index], ...issueComment }
        if (issueComment.mentionComments) {
          updatedComment.mentionComments = issueComment.mentionComments
        }
        state.issueComments.splice(index, 1, updatedComment)
      } else {
        state.issueComments.push(issueComment)
      }
    }
  },
  setIssueComments: (state, issueComments) => {
    state.issueComments = issueComments
  },
  setAddingComment: (state, addingComment) => {
    state.addingComment = addingComment
  },
  setCommentAdded: (state, commentAdded) => {
    state.commentAdded = commentAdded
  },
  setCommentRemoved: (state, commentRemoved) => {
    state.commentRemoved = commentRemoved
  },
  setRemovingComment: (state, removingComment) => {
    state.removingComment = removingComment
  },
  removeCommentFromIssueComment: (state, { commentId }) => {
    const index = state.issueComments.findIndex(comment => comment.id === commentId)
    state.issueComments.splice(index, 1)
  },
  setCommentUpdated: (state, commentUpdated) => {
    state.commentUpdated = commentUpdated
  },
  setUpdatingComment: (state, updatingComment) => {
    state.updatingComment = updatingComment
  }
}