export default {
  setAiAssistanceValue: (state, aiAssistanceValue) => {
    state.aiAssistanceValue = aiAssistanceValue
  },
  setAddingAiAssistance: (state, addingAiAssistance) => {
    state.addingAiAssistance = addingAiAssistance
  },
  setAiAssistanceAdded: (state, aiAssistanceAdded) => {
    state.aiAssistanceAdded = aiAssistanceAdded
  },
  resetAiAssistanceValue: state => {
    state.aiAssistanceValue = new Object()
  }
}