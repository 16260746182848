import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to domains page.
 * @param {*} to route to be navigated to.
 * @param {*} from route from where navigation was triggered.
 */
export const beforeDomains = async (to, from) => {
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.DOMAINS))
  store.commit("shared/setBreadcrumbs", undefined)
  let loadDomains
  if (to.query.dialog === "DomainAdd") {
    store.commit("domains/setDomainAdded", undefined)
    store.commit("domains/setAddingDomain", undefined)
    store.commit("domains/setDomainAddError", undefined)
  }
  if (to.query.dialog !== "DomainAdd" && from.query.dialog !== "DomainAdd") {
    loadDomains = store.dispatch("domains/loadDomains")
  }
  Promise.all([loadDomains]).then(() => {
    store.commit("shared/setProgressBarInitiated", false)
    store.commit("shared/setProgressBarPromisesPending", false)
  })
}