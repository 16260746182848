import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import store from "@/plugins/vuex"

const path = "/v1/issue-search"

export default {
  /**
   * This action will load issueSearch.
   * @param {*} context is the store.
   */
  async loadIssueSearch(context, payload) {
    const { id } = payload

    context.commit("setLoadingIssueSearch", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueSearchResponse = await httpClient.get(`/${id}`)

    if (getIssueSearchResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueSearch", getIssueSearchResponse.data)
    }

    context.commit("setLoadingIssueSearch", false)
  },

  /**
   * This action will load issueSearch by applying criteria.
   * @param {*} context is the store.
   * @param {*} payload is the searchText, issuesFilters and searchThroughAllIssueData.
   */
  async loadIssueSearchWithCriteria(context, payload) {
    context.commit("setLoadingIssueSearch", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postIssueSearchResponse = await httpClient.post(undefined, payload)

    if (postIssueSearchResponse.status === HTTP_STATUS_CODE.OK) {
      if (!payload.filterId) {
        const loggedInUser = context.rootGetters["auth/loggedInUser"]
        const users        = context.rootGetters["users/users"]
        const user         = users.find(user => user.id === loggedInUser.id)
        store.commit("users/updateUsers", [{ ...user, ...{ issuesFiltersPreference: payload.criteria?.issuesFilters } }])
      }
      context.commit("setIssueSearch", postIssueSearchResponse.data)
    }

    context.commit("setLoadingIssueSearch", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingIssueSearch", false)
    context.commit("setIssueSearch", new Object())
  }
}